import { INewsAndUpdateCard } from "@interface/propsInterface";
import {
  color,
  fontSize,
  fontWeight,
  screenBreakPoint,
} from "@styles/styleVariables";
import { convertHexToRGBA } from "@utils/convertHexToRGBA";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { pushItemUp } from "@styles/animations";
import { BrandButton } from "@components/reusables/brandButton";
import { ButtonEnum } from "src/enums/buttonsEnum";

export const NewsAndUpdatesSection = ({
  name,
  briefDescription,
  strapiId,
  publishedAt,
}: INewsAndUpdateCard) => {
  let publishedDate = new Date(publishedAt).toLocaleDateString(undefined, {
    year: "numeric",
    month: "short",
    day: "numeric",
  });

  return (
    <SNewsAndUpdatesCard variants={pushItemUp} whileInView="after" initial="before">
      <p className="news-and-updates-card-dates">{publishedDate}</p>
      <p className="news-and-updates-card-description">
        {briefDescription ?? ""}
      </p>
    </SNewsAndUpdatesCard>
  );
};

const SNewsAndUpdatesCard = styled(motion.div)`
  width: 100%%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: ${color.culture};
  border: ${color.oxfordBlue} solid 0.1rem;

  .news-and-updates-card-details {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .news-and-updates-card-title {
    font-weight: ${fontWeight.medium};
    font-size: ${fontSize.md};
    background: ${color.oxfordBlue};
    width: 100%;
    padding: 1rem;
    color: ${color.sunglow};
  }
  .news-and-updates-card-description {
    padding: 1rem;
    font-weight: ${fontWeight.light};
    color: ${color.oxfordBlue};
    font-size: ${fontSize.sm};
  }

  .news-and-updates-card-dates {
    margin-bottom: 1rem;
    padding: 1rem;
    font-weight: ${fontWeight.bold};
    color: ${color.oxfordBlue};
    font-size: ${fontSize.md};
  }

  .news-and-updates-card-registration {
    padding: 1rem;
  }
`;
