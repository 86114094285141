import { screenBreakPoint } from "@styles/styleVariables";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import styled from "styled-components";
import { NewsAndUpdatesSection } from "./newsAndUpdatesSection";
import { motion } from "framer-motion";
import { parentAnimation } from "@styles/animations";

export const NewsAndUpdatesContent = () => {
  const query = useStaticQuery(graphql`
    query newsAndUpdatesInformationQuery {
      newsAndUpdatesInformationQuery: allStrapiNewsAndUpdatesInformation(
        sort: { fields: publishedAt, order: DESC }
      ) {
        nodes {
          briefDescription
          strapi_id
          name
          useTemplate
          rank
          publishedAt
          sublink
        }
      }
    }
  `);

  const eventInformationQuery = query.newsAndUpdatesInformationQuery.nodes;

  return (
    <SNewsAndUpdateContent
      initial="before"
      whileInView="after"
      variants={parentAnimation(0.1)}
    >
      {eventInformationQuery?.map((mp: any) => (
        <NewsAndUpdatesSection
          name={mp?.name ?? ""}
          briefDescription={mp?.briefDescription ?? ""}
          strapiId={mp?.strapi_id ?? ""}
          buttonName={mp?.sublink?.name ?? ""}
          useTemplate={mp?.useTemplate ?? false}
          rank={mp?.rank ?? "9999"}
          publishedAt={mp?.publishedAt ?? "01/01/2001"}
        ></NewsAndUpdatesSection>
      ))}
    </SNewsAndUpdateContent>
  );
};

const SNewsAndUpdateContent = styled(motion.div)`
  min-height: 50vh;
  width: 100%;
  padding: 2rem 0rem;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 1rem;
`;
