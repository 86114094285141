import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import { Layout } from "@components/layout";
import { PageHeader } from "@components/reusables/pageHeader";
import { HeadMetadata } from "@components/headMetadata";
import { NewsAndUpdatesContent } from "@components/news-and-updates/newsAndUpdatesContent";

const NewsAndUpdatesIndex = () => {
  const query = useStaticQuery(graphql`
    query newsAndUpdatesPageQuery {
      pageHeaderQuery: strapiPageHeader(
        main_link: { url: { eq: "/news-and-updates" } }
      ) {
        id
        name
        description
        strapi_id
        coverImageGreyScale
        coverImageBlur
        coverImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100)
            }
          }
        }
      }
    }
  `);

  const pageHeaderQuery = query.pageHeaderQuery;

  return (
    <Layout>
      <HeadMetadata location="News And Updates"></HeadMetadata>
      <PageHeader
        name={pageHeaderQuery?.name ?? ""}
        description={pageHeaderQuery?.description ?? ""}
        coverImageGreyScale={pageHeaderQuery?.coverImageGreyScale ?? ""}
        coverImageBlur={pageHeaderQuery?.coverImageBlur ?? ""}
        coverImage={
          pageHeaderQuery?.coverImage?.localFile?.childImageSharp
            ?.gatsbyImageData ?? ""
        }
      ></PageHeader>
      <div className="content-wrapper">
        <NewsAndUpdatesContent></NewsAndUpdatesContent>
      </div>
    </Layout>
  );
};

export default NewsAndUpdatesIndex;
